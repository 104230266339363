<template>
    <div>
        <v-form id="cursos_externos_form" ref="cursos_externos_form">
            <v-row>
                <v-col cols="12">
                    <DashCard :title="nombreCompleto" title-icon="account_circle" >
                        <v-row class="ma-0">
                            <v-col cols="12" md="12">
                                <v-label>Seleccione del listado de cursos externos los cursos que el participante haya realizado.</v-label>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-label>Cursos Externos</v-label>
                                <v-autocomplete 
                                    solo flat outlined dense hide-details clearable 
                                    v-model="cursoExternoId" 
                                    :items="cursos_externos" 
                                    item-key="id" 
                                    item-text="nombre" 
                                    item-value="id" 
                                    prepend-inner-icon="folder" 
                                    placeholder="Seleccionar curso externo..."
                                    @change="seleccionarCurso" />
                            </v-col>  
                        </v-row>
                    </DashCard>
                </v-col>
                <v-col cols="12">
                    <DashCard title="Cursos" title-icon="account_circle" >
                        <v-simple-table v-if="cursos_externos_del_participante.length">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-center">Nombre curso</th>
                                        <th class="text-center">Fecha de realización</th>
                                        <th class="text-center">Fecha de vencimiento</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(curso, i) in cursos_externos_del_participante" v-bind:key="i">
                                        <td>
                                            <v-text-field solo outlined flat dense class="mt-6" readonly :rules="[rules.required]" v-model="curso.nombre_curso"/>
                                        </td>
                                        <td class="text-center">
                                            <DatePickerText v-model="curso.fecha_realizacion" :rules="[rules.required]" dense flat/>
                                        </td>
                                        <td class="text-center">
                                            <DatePickerText v-model="curso.fecha_vencimiento" :rules="[rules.required]" dense flat/>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <EmptyCard v-else class="ma-4" text="El participante no tiene cursos externos asociados" icon="error_outline" />
                    </DashCard>
                    <DashCard :actions="true" v-if="cursos_externos_del_participante.length" actions-align="right">
                        <template v-slot:actions>
                            <v-btn depressed large text color="primary" :to="{ name: 'dashboard_cursos_externos' }" exact>
                                <v-icon>close</v-icon>
                                <span class="ml-2 subtitle-2">Descartar</span>
                            </v-btn>
                            <v-btn depressed large color="primary" type="submit" @click="guardarCursos">
                                <v-icon>done</v-icon>
                                <span class="ml-2 subtitle-2">Guardar registro</span>
                            </v-btn>
                        </template>
                    </DashCard>
                </v-col>    
            </v-row>
        </v-form>
        <Message ref="dialog" />
        <Message ref="success_dialog" @onClose="$router.push({ name: 'dashboard_cursos_externos' })" />
    </div>
</template>

<script>
import { axiosHelper, messages, valid_required } from '@/helpers'
import DashCard from '@/shared/components/DashCard'
import EmptyCard from '@/shared/components/EmptyCard'
import DatePickerText from '@/shared/components/DatePickerText'
import DownloadDialog from '@/shared/components/DownloadDialog'
import Message from '@/shared/components/Message'

export default {
    components: {
        EmptyCard,
        DashCard,
        Message,
        DatePickerText,
        DownloadDialog
    },
    // define las propiedades que este componente espera recibir
    props: {
        idParticipante: {
            type: Number,
            required: true
        }
    },
    beforeRouteEnter(to, from, next) {
        // Convierte el parámetro a un número antes de entrar a la ruta
        const idParticipanteNumerico = Number(to.params.idParticipante);
        next(vm => {
            vm.idParticipante = idParticipanteNumerico;
        });
    },
    data: () => ({
        cursoExternoId:null,
        rules: {
            required: value => valid_required(value)
        },
        data: []
    }),
    computed: {
        participante() {
            return this.data?.participante || {};
        },
        cursos_externos(){
            return this.data?.cursos_externos|| [];
        },
        cursos_externos_del_participante(){
            return this.data?.cursos_externos_participante|| {};
        },
        nombreCompleto() {
            if (!this.data || !this.data.participante) {
                return 'Cargando...';  // O cualquier otro valor por defecto
            }
            const { first_name, last_name } = this.data.participante;
            return `${first_name} ${last_name}`;
        }
    },
    methods: {
        seleccionarCurso(id) {
            try {
                // Encuentra el curso en la lista de todos los cursos
                const curso = this.cursos_externos.find(curso => curso.id === id);

                if (!curso) {
                    console.error(`Curso con ID ${id} no encontrado en cursos_externos`);
                    return;
                }

                // Verificar si el curso ya está en `cursos_externos_participante`
                const yaExiste = this.data.cursos_externos_participante.some(participante => participante.curso_externo_id === id);

                if (!yaExiste) {
                    // Añade el nuevo contenido a la lista de contenidos seleccionados
                    this.data.cursos_externos_participante.push({
                        "nombre_curso": curso.nombre,
                        "fecha_realizacion": null,
                        "fecha_vencimiento": null,
                        "is_nuevo": true,
                        "perfil_id": this.idParticipante,
                        "curso_externo_id": id
                    });
                } else {
                    console.log(`El curso con ID ${id} ya ha sido añadido.`);
                }
            } catch (error) {
                console.error('Error al obtener los datos:', error);
            }
        },
        // este es el metodo que carga los datos al cargar el componente
        fetchData() {
            axiosHelper.get(`edu/editar-cursos-externos/${this.idParticipante}`)
                .then(response => {
                    this.data = response.data;
                })
                .catch(error => {
                    console.error("Error al cargar los datos", error);
                });
        },    
        descargarReporteExterno(id) {
            this.$refs.down_dialog.show(`edu/getcertif/${id}/`, null, `reporte.pdf`);
        },
        guardarCursos(e) {
            e.preventDefault();
            if(!this.$refs.cursos_externos_form.validate()) {
                this.$refs.dialog.show(messages.titles['error_generico'], messages.texts['error_check_fields'], 'error');
                return;
            }
            const data2={
                cursos_externos_participante:this.data.cursos_externos_participante,
            };
            axiosHelper.post('edu/editar-cursos-externos/'+this.idParticipante, data2)
                .then(() => this.success())
                .catch(response => this.error(response));
        },
        success() {
            this.$refs.success_dialog.show(messages.titles['success_generico'], messages.texts[this.editMode ? 'success_editar_curso_externo' : 'success_editar_curso_externo'], 'success');
        },
        error(response) {
            this.$refs.dialog.show(messages.titles['error_generico'], messages.texts['error_desconocido'], 'error');
        }
    },
    mounted() {
        this.$parent.$parent.set_title("Cursos externos", "report");
        this.fetchData();
    }
}
</script>